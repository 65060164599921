$(document).ready(function () {

  var tabsBox = $(".tabs"),
    carousel = $('.carousel'),
    productsCarousel = $(".products-carousel");

  // tips
  $('.js--tip').on('click', function () {
    $(this).next().toggle(100);
    return false;
  });

  if ($("#carousel").length) {

    var carInit = $("#carousel").waterwheelCarousel();

    $("[data-link-url]").on("click", function () {
      if ($(this).attr("data-link-url").length) {
        console.log($(this).data("link-url"));
        window.open(
          $(this).data("link-url"),
          '_blank'
        );
      }
    });
  }

  $(".dd").on("click", ">a", function (e) {
    e.preventDefault();
    $(this).closest('.dd').toggleClass('active');
  });

  if ($(".table-1").length) {

    $(".table-1").each(function () {
      var currentTable = $(this),
        firstRow = currentTable.find("tr:first"),
        labels = [];

      firstRow.find('th').each(function () {
        labels.push($(this).text())
      });


      currentTable.find("tr").each(function () {
        var currentRow = $(this);

        if (currentRow.find("td").length) {
          currentRow.find("td").each(function () {
            $(this).attr("data-label", labels[$(this).index()])
          });
        }

      });

    });

    $('body').on('click', '.table-1.table-content tr', function () {
      let $t = $(this), checkId = $(this).data('check-id'),
        $extra = $(".table-extra[data-check-id='" + checkId + "']", $(this).closest(".personal-table-item"));

      if ($extra.length) {
        $extra.toggleClass('visible');
        $t.toggleClass('active');
      }
    });
  }

  $(".instructions").on("click", "dt", function () {
    $(this).next().slideToggle();
    $(this).toggleClass('active');
  });

  // faq
  $(".faq-list").on('click', ".faq-list__item-head", function (e) {
    var currentItem = $(this),
      currentRow = currentItem.parent(),
      currentList = $(e.delegateTarget);

    if (currentRow.hasClass('active')) {
      currentRow.removeClass('active').find('.faq-list__item-content').slideUp();
    } else {
      currentList.find('.active').removeClass('active');
      currentList.find('.faq-list__item-content').slideUp();
      currentRow.addClass('active').find('.faq-list__item-content').slideDown();
    }
  });

  // responsive navigation toggle button
  $("#resp-toggle-btn").on('click', function () {
    $("body").toggleClass('navTrue');
  });

  if (productsCarousel.length) {
    // var mySwiper = new Swiper ('.products-carousel', {
    //     loop: true,
    //     slidesPerView: 'auto',
    //     centeredSlides : true
    //   })
  }

  // init of tabs
  if (tabsBox.length) {
    tabsBox.each(function () {
      var currentTabs = $(this);

      currentTabs.easyResponsiveTabs();
    });
  }

  // init of carousel

  if (carousel.length) {
    carousel.each(function () {
      var currentCarousel = $(this),
        items = currentCarousel.data('items'),
        singleItem = false,
        itemsDesc = currentCarousel.data('items-desc'),
        itemsDescSm = currentCarousel.data('items-desc-sm'),
        itemsTab = currentCarousel.data('items-tab');

      if (items < 1) {
        return false;
      }

      if (items == 1) {
        singleItem = 1;
        itemsDesc = 1;
        itemsDescSm = 1;
        itemsTab = 1;
      }

      currentCarousel.owlCarousel({
        items: items,
        singleItem: singleItem,
        itemsDesktop: [1199, itemsDesc],
        itemsDesktopSmall: [979, itemsDescSm],
        itemsTablet: [768, itemsTab],
        itemsTabletSmall: false,
        itemsMobile: [479, 1],
        navigation: true,
        slideSpeed: 1200,
        autoPlay: 3000,
        loop: true,
        navigationText: [" ", " "],
        autoHeight: true
      })
    })
  }

  $('.slider').slick({
    dots: true,
    // autoplay: true,
    // autoplaySpeed: 3000,
    fade: true,
    cssEase: 'linear'
  });


  $('input[data-mask]').each(function () {
    var $t = $(this), mask = $t.data('mask');
    if (typeof $t.attr('disabled') === 'undefined') {
      $t.inputmask('remove').inputmask('' + mask);
    }
  });

  $('body').on('click', '*[data-gtagcategory]', function (e) {
    e.preventDefault();
    let data = $(this).data();
    if (typeof gtag === 'undefined') {
      return false;
    }
    gtag('event', 'click', {
      'event_category': data.gtagcategory,
      'event_label': data.gtaglabel
    });
  });

  $(document).on('mouseup', function (e) {
    let $block = $('.question-btn-inner-mobile');

    if (!$block.is(e.target) && $block.has(e.target).length === 0) {
      $block.removeClass('question-btn-inner-mobile--visible');
    }
  });

  $('.question-btn').on('touchstart, click', function () {
    let $currentQuestionBtnInnerMobile = $(this).closest('.steps__item').find('.question-btn-inner-mobile');
    if ($currentQuestionBtnInnerMobile.hasClass('question-btn-inner-mobile--visible')) {
      return false;
    } else {
      $currentQuestionBtnInnerMobile.addClass('question-btn-inner-mobile--visible');
    }

  });

  // ADDED BY PSP: //
  // Дата рождения (регистрация / личные данные)
  $('input[name="user[bday]"]').each(function () {
    var matches = this.value.match(/(\d{2}).(\d{2}).(\d{4})/);
    if (matches !== null) {
      var d = parseInt(matches[1], 10), m = parseInt(matches[2], 10), Y = parseInt(matches[3], 10),
        $t = $(this), $b = $t.closest(".select_bday"), $sel = $b.find("select"), $sel_d = $sel.eq(0),
        $sel_m = $sel.eq(2), $sel_Y = $sel.eq(1);

      $sel_d.find('option[value="' + d + '"]').attr("selected", "selected");
      $sel_d.html($sel_d.html());

      $sel_m.find('option[value="' + m + '"]').attr("selected", "selected");
      $sel_m.html($sel_m.html());

      $sel_Y.find('option[value="' + Y + '"]').attr("selected", "selected");
      $sel_Y.html($sel_Y.html());
    }
  });
  // Фикс дат до 1..9
  $('.select_bday select option').each(function () {
    var $t = $(this), v = 0;
    if (!isNaN(this.value)) {
      v = parseInt(this.value, 10);
      if (!isNaN(v) && v < 10) {
        $t.html("0" + v);
      }
    }
  });
  // Месяцы
  $('.select_bday select[name*="[month]"] option').each(function () {
    var $t = $(this), v = $t.val(), m = '';
    switch (v) {
      case '1':
        m = 'Январь';
        break;
      case '2':
        m = 'Февраль';
        break;
      case '3':
        m = 'Март';
        break;
      case '4':
        m = 'Апрель';
        break;
      case '5':
        m = 'Май';
        break;
      case '6':
        m = 'Июнь';
        break;
      case '7':
        m = 'Июль';
        break;
      case '8':
        m = 'Август';
        break;
      case '9':
        m = 'Сентябрь';
        break;
      case '10':
        m = 'Октябрь';
        break;
      case '11':
        m = 'Ноябрь';
        break;
      case '12':
        m = 'Декабрь';
        break;
    }
    if (m !== '') {
      $t.html(m);
    }
  });

  $('.form__drag-label').on({
    dragenter: function (event) {
      event.preventDefault();
      $(this).closest('.form__drag-body').addClass('form__drag-body--dragover');
    },

    dragleave: function (event) {
      event.preventDefault();

      $(this).closest('.form__drag-body').removeClass('form__drag-body--dragover');
    },

    drop: function (event) {
      event.preventDefault();
      event.stopPropagation();

      $('.form__drag-body').hide();
      $('.form__drag-preview').show();

      let file = event.originalEvent.dataTransfer.files;
      let reader = new FileReader();

      reader.onload = function (event) {
        $('.form__drag-image').attr('src', event.target.result);
      };

      if (file.length > 0) {
        reader.readAsDataURL(file[0]);
      }
    }
  });

  function readURL(input) {
    if (input.files && input.files[0]) {
      $('.form__drag-preview').show();
      $('.form__drag-body').hide();

      var reader = new FileReader();
      reader.onload = function (e) {
        $('.form__drag-image').attr('src', e.target.result);
      };

      reader.readAsDataURL(input.files[0]);
    }
  }

  $('.form__drag-input').change(function () {
    readURL(this);
  });

  $("html").on("drop", function (e) {
    e.preventDefault();
    e.stopPropagation();
  });

  $("html").on("dragover", function (e) {
    e.preventDefault();
    e.stopPropagation();
  });

  $('body').on('click', '.js--scroll_to_reviews', function () {
    if (location.pathname.indexOf('personal') > -1) {
      jewelryModals.hide();
    }
  });

  //
  $("body").on("change", ".select_bday select", function (e) {
    var $form = $(this.form), $hidden = $form.find('input[name*="[bday]"]'),
      $sel = $form.find('.select_bday select'),
      d = $sel.eq(0).val(), m = $sel.eq(1).val(), Y = $sel.eq(2).val();

    if (isNaN(d)) d = 0;
    if (isNaN(m)) m = 0;
    if (isNaN(Y)) Y = 0;

    d = (d < 10) ? "0" + d : d;
    m = (m < 10) ? "0" + m : m;

    $hidden.val(d + "." + m + "." + Y);
  });
  $(".select_bday select:eq(0)").trigger("change");


  $('select[data-selected]').each(function () {
    var $t = $(this), selected = $t.data('selected');
    $t.find('option').each(function () {
      var $o = $(this);
      if ($o.val() == selected) {
        $o.attr('selected', 'selected');
      }
    });
    $t.html($t.html());
  });
  ///////

  $('.datepicker').datepicker({
    dateFormat: 'dd.mm.yy'
  });

  $('body').on('click', '.js--scroll_to_reviews', function () {
    if (location.pathname.indexOf('personal') > -1) {
      jewelryModals.hide();
    }
  });

  $('body').on('click', '.table-layout__mobile-item:first-child', function () {
    $(this).toggleClass('table-layout__mobile-item--active');
    $('.table-layout__mobile-item:last-child', $(this).closest('.table-layout-mobile-part'))
      .not('.js--resp__list .table-layout__mobile-item:last-child').slideToggle();
    sliderInit($(this));
  });

  if ($('.table-slider').length) {
    for (let i = 0; i < $('.table-slider').length; i++) {
      let $currentSlider = $('.table-slider').get(i);
      let totalSlides = $('.table-slide', $currentSlider).length;
      $('.table-slider-info', $currentSlider.closest('.table-slider-wrapper')).text(1 + ' / ' + totalSlides);
    }
  }

  function sliderInit(context) {
    let $parent = context.siblings('.table-layout__mobile-item');
    let $slickElement = $('.table-slider', $parent);

    $slickElement.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      prevArrow: $('.table-slider-arrow-prev', $parent),
      nextArrow: $('.table-slider-arrow-next', $parent)
    });
  }

  $('.table-slider').on('afterChange', function (event, slick, currentSlide) {
    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
    var i = (currentSlide ? currentSlide : 0) + 1;
    $('.table-slider-info', $(this).closest('.table-slider-wrapper')).text(i + ' / ' + slick.slideCount);
  });

  $(document).on('click', '.js--show_review', function (e) {
    e.preventDefault();

    if ($('.js-modal-review').length > 0) {
      jewelryModals.show('.js-modal-review:eq(0)');
    } else {
      jewelryModals.show('.js-modal-need_check');
    }
  });

  $('#selectize__select').selectize({
    create: false,
    closeAfterSelect: true,
    sortField: 'text',
    onChange: function (value) {
      $('.places__item').hide();
      $('.places__item[data-for="' + value + '"]').show();
    }
  });

  $('a[data-event]').on('click', function (e) {
    e.preventDefault();
    let $t = $(this), href = $t.prop('href'), target = $t.prop('target');
    if (typeof gtag !== 'function') {
      gtag = function () {};
    }

    if (typeof gtag === 'function') {
      gtag('event', $t.data('event'), {'event_category': $t.data('category')});
      let _h = href.replace(/http[^\/]+\/\/[^\/]+/, '');
      if ((['/', '', '#'].indexOf(_h) < 0) && (['blank', '_blank'].indexOf(target) < 0)) {
        e.preventDefault();

        setTimeout(function () {
          location.href = href;
        }, 399);
      }
    }
  });

  let count = 1;

  (function loop() {
    count++;
    setTimeout(function () {
      $('.slider__fade').removeClass('active');
      $(`.slider__fade-${count}`).addClass('active');
      if (count === 2) {
        count = 0;
      }
      loop()
    }, 2000);
  }());

  // items
  $('.modal__link--plus').each(function () {
    let $t = $(this), $row = $t.closest('form').find('.js--good__row:last');
    $t.data('row', $row.clone());
  }).on('click', function (e) {
    e.preventDefault();
    let $t = $(this), $row = $t.closest('form').find('.js--good__row:last');
    $row.after($t.data('row').clone());
    $('.js--items__errors').hide();
  });
});
