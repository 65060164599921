/**
 * Created by PetePearl on 20.05.2016.
 * contact: pzhemchugov@gmail.com
 */

$(document).ready(function () {
  window.jewelryModals = {
    bodyPadding: 0,
    $body: $(document.body),

    //определение наличия скроллбара в body
    isScroll: function () {
      return (document.body.scrollHeight > $(window).height())
    },

    //вычисление ширины скроллбара body
    getScrollWidth: function () {
      var div = $('<div>').css({
        position: "absolute",
        top: "0",
        left: "0",
        width: "100px",
        height: "100px",
        visibility: "hidden",
        overflow: "scroll"
      });

      $('body').eq(0).append(div);

      var width = div.get(0).offsetWidth - div.get(0).clientWidth;

      div.remove();

      return width;
    },

    //скрытие модального окна
    hide: function (options) {
      options = $.extend({
        beforeHide: function () {
        },
        afterHide: function () {
        }
      }, options);

      options.beforeHide();

      $('.modals__fog').fadeOut('fast');
      $('.modal:visible').fadeOut('fast', function () {
        options.afterHide();

        if (jewelryModals.isScroll()) {
          $('body')
            .css({'padding-right': 0})
            .removeClass('body-modal');
        }

        $('.modal').off('click');
      });
    },

    //открытие модального окна
    show: function (className, options) {
      options = $.extend({
        fog: true,
        close: true,
        title: false,
        message: false,
        speed: 300,

        beforeShow: function () {
        },
        afterShow: function () {
        },
        beforeHide: function () {
        },
        afterHide: function () {
          //if ($(".video-modal").length) {
          //  $(".video-modal").find("video").get(0).pause();
          //}
        }
      }, options);

      className = (className[0] == '.') ? className : '.' + className;

      options.beforeShow();

      if (jewelryModals.isScroll()) {
        $('body')
          .css({'padding-right': jewelryModals.getScrollWidth()})
          .addClass('body-modal');
      }

      if (options.fog) {
        $('.modals__fog').fadeIn(options.speed);
      }

      // fix iframe
      (function () {
        let $modal = $(className), $ifr = $modal.find('iframe'), obh = options.beforeHide;
        if ($ifr.length && $ifr.attr('src').indexOf('youtube')) {
          $modal.data('ifr', $ifr.clone());

          options.beforeHide = function () {
            $ifr.after($modal.data('ifr'));
            $ifr.remove();
            obh();
          }

          $ifr.attr('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture');
          $ifr.attr('src', $ifr.attr('src') + '?autoplay=1');
        }
      })();
      //

      if (options.close) {
        let closeOptions = {
          beforeHide: options.beforeHide,
          afterHide: options.afterHide
        };

        $('.modal__close, .js--modal__close', $(className)).show().one('click', function () {
          jewelryModals.hide(closeOptions);
          return false;
        });

        $('.modal__content, .modal__wrapper').on('click', function (e) {
          if (e.target !== this)
            return;

          jewelryModals.hide(closeOptions);
        });
      } else {
        $('.modal__close', $(className)).hide();
      }

      if (options.title) {
        $('.modal__header', $(className)).html('<div class="modal__title">' + options.title + '</div>');
      }

      if (options.message) {
        $('.modal__content', $(className)).html(options.message);
      }

      $('.modal:visible').fadeOut();
      $(className).fadeIn(options.speed, function () {
        options.afterShow();
      });
    },

    //бинды событий
    bind: function () {
      $(document).on('click', '[data-modal]', function (e) {
        e.preventDefault();
        let modal = $(this).data('modal');
        jewelryModals.show(modal);
      });
    },

    init: function () {
      this.bind();
    }
  };

  jewelryModals.init();
});

