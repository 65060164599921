// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start();
// require("@rails/activestorage").start();
require("jquery");
require("jquery-ui");
require("jquery-ujs");
jQuery.migrateMute = true;
require('jquery-migrate');
require("jquery.qrcode");

window.jsQR = require('jsqr');
import QrScanner from 'qr-scanner';
window.QrScanner = QrScanner;
window.raf = require('raf');
import debounce from 'lodash.debounce';
window.debounce = debounce;

require('ismobilejs');
import isMobile from 'ismobilejs';
window.isMobile = isMobile();

require('packs/jq/jquery.waterwheelCarousel')
require('packs/jq/jquery.disableAutoFill.min')

require('packs/kladr/core')
require('packs/kladr/kladr')
require('packs/kladr/kladr_zip')

require('packs/jq/easyResponsiveTabs')
require('packs/jq/swiper.jquery')
// require('packs/jq/owl.carousel')
require('packs/jq/slick.min.js')
require('packs/jq/jewelryModals')
require('packs/jq/selectize')
require('packs/jq/custom')

require('packs/psp/loader')
require('packs/psp/multipart-form')
require('packs/psp/core_functions')
require('packs/psp/users')
require('packs/psp/products')

require('packs/psp/js_qr')
